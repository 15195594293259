import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateHau,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);

  useEffect(() => {
    if (select_data) {
      // const selectedHub = (select_data.hubId || []).map((hubId) => ({
      //   value: hubId,
      //   label: hubs.find((hub) => hub._id === hubId)?.name || "Unknown Hub",
      // }));
      console.log("select_data.hubId:", select_data.hubId);
      const selectedHub = (select_data.hubId || []).map((hub) => {
        const matchingHub = hubs.find((hubItem) => hubItem._id === hub._id);
        return {
          value: hub._id,
          label: matchingHub?.name || hub.name || `Unknown Hub (${hub._id})`,
        };
      });

      console.log("selectedHub:", selectedHub);

      setUpdate_modal_data({
        ...select_data,
        hubId: selectedHub,
        adminId: select_data.adminId ? select_data.adminId._id : "",
      });
    }
  }, [select_data, hubs]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  useEffect(() => {
    const fetchHubs = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHubs(response.data.hubs || []);
      } catch (error) {
        console.error("Error fetching hubs:", error);
      }
    };
    const fetchUsers = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchHubs();
    fetchUsers();
  }, []);

  const handleHubChange = (selectedOptions) => {
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      hubId: selectedOptions,
    }));
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      adminId: value || null,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.adminId) {
      newErrors.adminId = "User is required";
    }
    if (!update_modal_data.hubId || update_modal_data.hubId.length === 0) {
      newErrors.hubId = "At least one hub must be selected";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem("token");
          const { _id, adminId, hubId } = update_modal_data;

          // Corrected key from hubId to hubIds
          const hubIdsArray = hubId.map((hub) => hub.value);

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/hubAdmin/update/${_id}`,
            { hubIds: hubIdsArray, adminId }, // Update the key here
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateHau(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating hub Admin:", error);
        }
        set_update_data_modal_Show(false);
      }
    });
  };

  return (
    <Modal show={add_data_modal_Show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Hub Admin Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="adminId">
            <Form.Label>User</Form.Label>
            <Form.Control
              as="select"
              name="adminId"
              value={update_modal_data.adminId || ""}
              onChange={handleUserChange}
              isInvalid={!!errors.adminId}
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name || "Unknown Name"} (
                  {user.phonenumber || "Unknown Phone"})
                </option>
              ))}
            </Form.Control>
            {errors.adminId && (
              <Form.Text className="text-danger">{errors.adminId}</Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Assign Hubs *</Form.Label>
            <Select
              name="hubId"
              value={update_modal_data.hubId || []}
              options={hubs.map((hub) => ({
                value: hub._id,
                label: hub.name,
              }))}
              isMulti
              onChange={handleHubChange}
              classNamePrefix="select"
            />
            {errors.hubId && (
              <Form.Text className="text-danger">{errors.hubId}</Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
