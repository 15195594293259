import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Layouts from "../../layouts/layouts";
import DataTable from "react-data-table-component";

export default function HubHistory() {
  const { id } = useParams();
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/hubVehicleHistory/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.hubVehicleHistory)) {
        setHistory(response.data.hubVehicleHistory);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const columns = [
    {
      name: <b>Date</b>,
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>Vehicle Count</b>,
      selector: (row) => row.vehicleCount || "N/A",
      sortable: true,
    },
    {
      name: <b>Hub</b>,
      selector: (row) => row.id_hub?.name || "N/A",
      sortable: true,
    },
  ];

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                {"Hub History"}
              </div>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={history}
                  pagination
                  fixedHeader
                  highlightOnHover
                  responsive
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
