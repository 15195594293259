import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { State } from "country-state-city";

export default function FilterModal({
  show,
  onHide,
  cityFilter,
  setCityFilter,
  stateFilter,
  setStateFilter,
  applyFilters,
  removeFilters,
  statusFilter,
  setStatusFilter,
  apidata,
}) {
  const [filteredCities, setFilteredCities] = useState([]);

  const getStateName = (stateCode) => {
    const state = State.getStateByCodeAndCountry(stateCode, "IN");
    return state ? state.name : stateCode;
  };

  useEffect(() => {
    if (stateFilter) {
      const cities = Array.from(
        new Set(
          apidata
            .filter((item) => item.state === stateFilter)
            .map((item) => item.city)
        )
      ).filter(Boolean);
      setFilteredCities(cities);
      setCityFilter("");
    } else {
      const allCities = Array.from(
        new Set(apidata.map((item) => item.city))
      ).filter(Boolean);
      setFilteredCities(allCities);
    }
  }, [stateFilter, apidata, setCityFilter]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="stateFilter">
            <Form.Label>State</Form.Label>
            <Form.Control
              as="select"
              value={stateFilter}
              onChange={(e) => setStateFilter(e.target.value)}
            >
              <option value="">All States</option>
              {Array.from(new Set(apidata.map((item) => item.state)))
                .filter(Boolean)
                .map((stateCode, index) => (
                  <option key={index} value={stateCode}>
                    {getStateName(stateCode)}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="cityFilter">
            <Form.Label>City</Form.Label>
            <Form.Control
              as="select"
              value={cityFilter}
              onChange={(e) => setCityFilter(e.target.value)}
              disabled={!stateFilter}
            >
              <option value="">All Cities</option>
              {filteredCities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="statusFilter">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
