import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";
import axios from "axios";

export default function DataTable_Component({
  apidata,
  columns,
  searchBy,
  baseUrl,
}) {
  const [dataFilter, setDataFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [dateFilter, setDateFilter] = useState(""); // For storing selected date
  const [loading, setLoading] = useState(false); // For loading state

  useEffect(() => {
    // Initialize filtered data with original API data
    setFilteredData(apidata);
  }, [apidata]);

  const applyFilters = async () => {
    setShowFilterModal(false); // Close modal when filters are applied

    if (dateFilter) {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v2/vehicles/daily/summaries/hub`,
          {
            params: { date: dateFilter },
          }
        );

        if (response?.data?.success) {
          setFilteredData(response.data.hubs || []); // Update table with filtered data
        } else {
          setFilteredData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFilteredData([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const removeFilters = () => {
    setShowFilterModal(false);
    setDateFilter(""); // Reset date filter
    setFilteredData(apidata); // Reset table data to original data
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        dateFilter={dateFilter} // Pass selected date to the modal
        setDateFilter={setDateFilter} // Update date filter from modal
      />
    </div>
  );
}
