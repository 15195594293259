import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/payouts/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/payouts/update_data_modal";
import PayoutDetailModal from "../../components/payouts/Payout Details";

export default function Payout() {
  const title = "Payout Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [salaryCount, setSalaryCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showPayoutDetailModal, setShowPayoutDetailModal] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Hub Name</b>,
      selector: (row) => (row.hubId ? row.hubId.name : "-"),
      sortable: true,
    },
    {
      name: <b>Client Name</b>,
      selector: (row) => (row.clientId ? row.clientId.name : "-"),
      sortable: true,
    },
    {
      name: <b>Vehicle Type</b>,
      selector: (row) => row.vehicleType,
      sortable: true,
    },
    {
      name: <b>Order Picked Amount</b>,
      selector: (row) => (row.amount ? row.amount.orderPicked : "-"),
      sortable: true,
    },
    {
      name: <b>Order Delivered Amount</b>,
      selector: (row) => (row.amount ? row.amount.orderDeliver : "-"),
      sortable: true,
    },
    {
      name: <b>Threshold</b>,
      selector: (row) => row.threshold,
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => viewPayout(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/payouts`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.payouts)) {
        setApiState(response.data.payouts);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const viewPayout = (payout) => {
    setSelectedPayout(payout);
    setShowPayoutDetailModal(true);
  };
  const updatePayout = (updatedPayout) => {
    setApiState((prev) =>
      prev.map((payout) =>
        payout._id === updatedPayout._id ? updatedPayout : payout
      )
    );
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v2/payout/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Payout has been deleted.", "success");
            fetchData(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Payout could not be deleted.", "error");
            console.error("Error deleting salary:", error);
          });
      }
    });
  };
  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdatePayout={updatePayout}
      />
      <PayoutDetailModal
        show={showPayoutDetailModal}
        onHide={() => setShowPayoutDetailModal(false)}
        payout={selectedPayout}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create-payout">
                  <div className="btn btn-info btn-sm text-white">
                    Add Payout
                  </div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="clientId"
                    title_btn={true}
                    title="Payout List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
