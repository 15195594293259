import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // Import the download icon

const ComplianceDetailModal = ({ show, onHide, compliance }) => {
  if (!compliance) return null;

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Compliance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>User Name:</strong>{" "}
          {compliance.id_user ? compliance.id_user.name : "N/A"}
        </p>
        <p>
          <strong>License Number:</strong> {compliance.license_number}
        </p>
        <p>
          <strong>Aadhar Card Number:</strong> {compliance.aadhar_number}
        </p>
        <p>
          <strong>Client Name:</strong>
          {Array.isArray(compliance.id_client) &&
          compliance.id_client.length > 0 ? (
            compliance.id_client.map((client, index) => (
              <div key={client._id}>
                <span>
                  Client {index + 1} - {client.name}
                </span>
                {client.phonenumber && <span>, {client.phonenumber}</span>}
              </div>
            ))
          ) : (
            <span> N/A</span>
          )}
        </p>
        <p>
          <strong>Verification Done:</strong>{" "}
          {compliance.client_id_verification?.isDone !== undefined
            ? compliance.client_id_verification.isDone
              ? "Yes"
              : "No"
            : "N/A"}
        </p>
        <p>
          <strong>Status:</strong> {compliance.status}
        </p>
        <p>
          <strong>Offer Letter:</strong>{" "}
          {compliance.offer_letter ? "Yes" : "No"}
        </p>
        <p>
          <strong>Induction:</strong> {compliance.induction ? "Yes" : "No"}
        </p>
        <p>
          <strong>Is Consent:</strong> {compliance.isConsent ? "Yes" : "No"}
        </p>
        <p>
          <strong>Created By:</strong>{" "}
          {compliance.id_user ? compliance.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Updated At:</strong>{" "}
          {formatDate(compliance.updatedAt || "N/A")}
        </p>

        {/* <p>
          <strong>Aadhar Card :</strong>
          {compliance.aadhar_id ? (
            <div>
              <div className="d-flex justify-content-center">
                <img
                  src={compliance.aadhar_id} // Assuming compliance.aadhar_id is a URL or file path
                  alt="Aadhar Card"
                  style={{ maxWidth: "50%", height: "auto" }}
                />
                <a
                  href={compliance.aadhar_id} // Link to the image file
                  download
                  className="btn btn-link p-0" // No padding, and link style
                >
                  <FontAwesomeIcon icon={faDownload} size="lg" />{" "}
                </a>
              </div>
            </div>
          ) : (
            <p>No Identification Image Available</p>
          )}
        </p> */}

        {/* <p>
          <strong>Driver License :</strong>
          {compliance.license_id ? (
            <div>
              <div className="d-flex justify-content-center">
                <img
                  src={compliance.license_id} // Assuming compliance.aadhar_id is a URL or file path
                  alt="Driver License"
                  style={{ maxWidth: "50%", height: "auto" }}
                />
                <a
                  href={compliance.license_id} // Link to the image file
                  download
                  className="btn btn-link p-0" // No padding, and link style
                >
                  <FontAwesomeIcon icon={faDownload} size="lg" />{" "}
                </a>
              </div>
            </div>
          ) : (
            <p>No Identification Image Available</p>
          )}
        </p> */}

        {/* <p>
          <strong>Police Verification :</strong>
          {compliance.police_verification ? (
            <div>
              <div className="d-flex justify-content-center">
                <img
                  src={compliance.police_verification} // Assuming compliance.aadhar_id is a URL or file path
                  alt="Driver License"
                  style={{ maxWidth: "50%", height: "auto" }}
                />
                <a
                  href={compliance.police_verification} // Link to the image file
                  download
                  className="btn btn-link p-0" // No padding, and link style
                >
                  <FontAwesomeIcon icon={faDownload} size="lg" />{" "}
                </a>
              </div>
            </div>
          ) : (
            <p>No Identification Image Available</p>
          )}
        </p> */}

        {/* <p>
          <strong>Consent Document :</strong>
          {compliance.consent_document ? (
            <div>
              <div className="d-flex justify-content-center">
                <img
                  src={compliance.consent_document} // Assuming compliance.aadhar_id is a URL or file path
                  alt="Driver License"
                  style={{ maxWidth: "50%", height: "auto" }}
                />
                <a
                  href={compliance.consent_document} // Link to the image file
                  download
                  className="btn btn-link p-0" // No padding, and link style
                >
                  <FontAwesomeIcon icon={faDownload} size="lg" />{" "}
                </a>
              </div>
            </div>
          ) : (
            <p>No Identification Image Available</p>
          )}
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ComplianceDetailModal;

// import React from "react";
// import { Modal, Button } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faDownload } from "@fortawesome/free-solid-svg-icons";

// const ComplianceDetailModal = ({ show, onHide, compliance }) => {
//   if (!compliance) return null;

//   const formatDate = (dateString) => {
//     if (!dateString) return "N/A";
//     const options = { year: "numeric", month: "2-digit", day: "2-digit" };
//     return new Date(dateString).toLocaleDateString(undefined, options);
//   };

//   const renderImageSection = (label, imageUrl) => (
//     <p>
//       <strong>{label}:</strong>
//       {imageUrl ? (
//         <div className="text-center">
//           <img
//             src={imageUrl}
//             alt={`${label}`}
//             style={{
//               maxWidth: "100%",
//               height: "auto",
//               border: "1px solid #ccc",
//               marginBottom: "10px",
//               borderRadius: "8px",
//             }}
//           />
//           <a
//             href={imageUrl}
//             download
//             className="btn btn-link p-0"
//             style={{
//               textDecoration: "none",
//               color: "#007bff",
//               fontWeight: "bold",
//             }}
//           >
//             <FontAwesomeIcon icon={faDownload} /> Download
//           </a>
//         </div>
//       ) : (
//         <span> No Image Available</span>
//       )}
//     </p>
//   );

//   return (
//     <Modal show={show} onHide={onHide}>
//       <Modal.Header closeButton>
//         <Modal.Title>Compliance Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p>
//           <strong>User Name:</strong>{" "}
//           {compliance.id_user ? compliance.id_user.name : "N/A"}
//         </p>
//         <p>
//           <strong>License Number:</strong> {compliance.license_number || "N/A"}
//         </p>
//         <p>
//           <strong>Aadhar Card Number:</strong>{" "}
//           {compliance.aadhar_number || "N/A"}
//         </p>
//         <p>
//           <strong>Client Name:</strong>
//           {Array.isArray(compliance.id_client) &&
//           compliance.id_client.length > 0 ? (
//             compliance.id_client.map((client, index) => (
//               <div key={client._id}>
//                 <span>
//                   Client {index + 1} - {client.name || "N/A"}
//                 </span>
//                 {client.phonenumber && <span>, {client.phonenumber}</span>}
//               </div>
//             ))
//           ) : (
//             <span> N/A</span>
//           )}
//         </p>

//         {renderImageSection("Aadhar Card", compliance.aadhar_id)}
//         {renderImageSection("Driver License", compliance.license_id)}
//         {renderImageSection(
//           "Police Verification",
//           compliance.police_verification
//         )}
//         {renderImageSection("Consent Document", compliance.consent_document)}

//         <p>
//           <strong>Status:</strong> {compliance.status || "N/A"}
//         </p>
//         <p>
//           <strong>Updated At:</strong> {formatDate(compliance.updatedAt)}
//         </p>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ComplianceDetailModal;
