import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import { Country, State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import Layouts from "../../layouts/layouts";

export default function Create_hub() {
  const title = "Add Hub";
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    city: "",
    country: "",
    state: "",
    coordinates: { latitude: "", longitude: "" },
    public_id: "",
    image_url: null,
    hubimage: null,
    area: "",
    count: "",
    status: "Active",
  });
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = Country.getAllCountries().map((country) => ({
        name: country.name,
        code: country.isoCode,
      }));
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      const stateData = State.getStatesOfCountry(selectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setStates(stateData);
      setCities([]);
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if (selectedStateCode && selectedCountryCode) {
      const cityData = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, selectedCountryCode]);

  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];

  const onChangeHandler = (event) => {
    const { name, value, files } = event.target;
    if (name === "latitude" || name === "longitude") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: {
          ...prevFormData.coordinates,
          [name]: value,
        },
      }));
    } else if (name === "hubimage") {
      const file = files[0];
      if (file.size > 1048576) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          hubimage: "Image size must not exceed 1MB",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          hubimage: "",
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          hubimage: file,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      if (name === "country") setSelectedCountryCode(value);
      if (name === "state") setSelectedStateCode(value);
    }
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: { latitude: lat, longitude: lng },
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Hub Name is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.area) newErrors.area = "Area is required";
    if (!formData.coordinates.latitude || !formData.coordinates.longitude)
      newErrors.coordinates = "Location is required";
    if (!formData.hubimage) newErrors.hubimage = "Hub Image is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (errors.hubimage) {
      alert(errors.hubimage);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("address", formData.address);
      formDataToSubmit.append("city", formData.city);
      formDataToSubmit.append("country", formData.country);
      formDataToSubmit.append("state", formData.state);
      formDataToSubmit.append("public_id", formData.public_id);
      formDataToSubmit.append("image_url", formData.image_url);
      formDataToSubmit.append("area", formData.area);
      formDataToSubmit.append("count", formData.count);
      formDataToSubmit.append("status", formData.status);

      formDataToSubmit.append(
        "coordinates[latitude]",
        formData.coordinates.latitude
      );
      formDataToSubmit.append(
        "coordinates[longitude]",
        formData.coordinates.longitude
      );

      if (formData.hubimage) {
        formDataToSubmit.append("hubimage", formData.hubimage);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/hub/new`,
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/hub");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="name">
                  <b>Hub Name *</b>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Hub-Name"
                  className="form-control"
                  onChange={onChangeHandler}
                  value={formData.name}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name}</small>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="address">
                  <b>Address *</b>
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  className="form-control"
                  value={formData.address}
                  onChange={onChangeHandler}
                />
                {errors.address && (
                  <small className="text-danger">{errors.address}</small>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="country">
                  <b>Country</b>
                </label>
                <select
                  name="country"
                  className="form-control"
                  value={formData.country || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="state">
                  <b>State</b>
                </label>
                <select
                  name="state"
                  className="form-control"
                  value={formData.state || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="city">
                  <b>City *</b>
                </label>
                <select
                  name="city"
                  className="form-control"
                  value={formData.city || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.code} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && (
                  <small className="text-danger">{errors.city}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="area">
                  <b>Area *</b>
                </label>
                <input
                  type="number"
                  name="area"
                  placeholder="Area"
                  className="form-control"
                  value={formData.area}
                  onChange={onChangeHandler}
                />
                {errors.area && (
                  <small className="text-danger">{errors.area}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="count">
                  <b>Vehicle Count </b>
                </label>
                <input
                  type="number"
                  name="count"
                  placeholder="Count of Vehicle"
                  className="form-control"
                  value={formData.count}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="coordinates">
                  <b>Coordinates *</b>
                </label>
                <input
                  type="text"
                  name="coordinates"
                  placeholder="Latitude, Longitude"
                  className="form-control"
                  value={`${formData.coordinates.latitude}, ${formData.coordinates.longitude}`}
                  readOnly
                />
                {errors.coordinates && (
                  <small className="text-danger">{errors.coordinates}</small>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="">
                  <b>Location</b>
                </label>
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_BASE_API}
                  libraries={["places"]}
                >
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      placeholder="Search places..."
                      className="form-control"
                    />
                  </Autocomplete>
                </LoadScript>
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="hubimage">
                  <b>Hub Image </b>
                </label>
                <input
                  type="file"
                  name="hubimage"
                  placeholder="Hub Image"
                  className="form-control"
                  onChange={onChangeHandler}
                  accept="image/*"
                />
                {errors.hubimage && (
                  <small className="text-danger">{errors.hubimage}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b>Status *</b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {errors.status && (
                  <p className="text-danger">{errors.status}</p>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Hub
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
