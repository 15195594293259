import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";
import { extractCityFromLocation } from "../../../utils/extractCity";

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [vehicleTypeFilter, setVehicleTypeFilter] = useState("");
  const [vehicleCompanyFilter, setVehicleCompanyFilter] = useState("");
  const [hubFilter, setHubFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [vehicleCompanyCounts, setVehicleCompanyCounts] = useState({});
  const [cityFilter, setCityFilter] = useState("");

  useEffect(() => {
    let filtered = apidata;

    if (statusFilter) {
      filtered = filtered.filter(
        (item) =>
          item.status &&
          item.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (vehicleTypeFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_type &&
          item.id_type.vehiclename
            .toLowerCase()
            .includes(vehicleTypeFilter.toLowerCase())
      );
    }

    if (hubFilter) {
      filtered = filtered.filter((item) => {
        if (Array.isArray(item.id_hub)) {
          return item.id_hub.some(
            (hub) =>
              hub.name &&
              hub.name.toLowerCase().includes(hubFilter.toLowerCase())
          );
        } else if (item.id_hub && item.id_hub.name) {
          return item.id_hub.name
            .toLowerCase()
            .includes(hubFilter.toLowerCase());
        } else {
          return false;
        }
      });
    }

    if (vehicleCompanyFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_type &&
          item.id_type.company
            .toLowerCase()
            .includes(vehicleCompanyFilter.toLowerCase())
      );
    }

    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "city") {
          return (
            item.city &&
            item.city.toLowerCase().includes(dataFilter.toLowerCase())
          );
        }
        if (searchBy === "numberplate") {
          return (
            item.numberplate &&
            item.numberplate.toLowerCase().includes(dataFilter.toLowerCase())
          );
        }
        if (searchBy === "name") {
          return (
            item.name &&
            item.name.toLowerCase().includes(dataFilter.toLowerCase())
          );
        }
        if (searchBy === "vendorname") {
          return (
            item.vendorname &&
            item.vendorname.toLowerCase().includes(dataFilter.toLowerCase())
          );
        }
        return false;
      });
    }
    if (cityFilter) {
      filtered = filtered.filter((item) => {
        const city = extractCityFromLocation(item.assignedlocation);
        return city && city.toLowerCase().includes(cityFilter.toLowerCase());
      });
    }

    setFilteredData(filtered);

    // Calculate vehicle company counts
    const counts = filtered.reduce((acc, item) => {
      if (
        item.id_type &&
        item.id_type.company &&
        !["Inactive", "Unassigned", "Service_Center"].includes(item.status)
      ) {
        const company = item.id_type.company;
        acc[company] = (acc[company] || 0) + 1;
      }
      return acc;
    }, {});

    setVehicleCompanyCounts(counts);
  }, [
    dataFilter,
    statusFilter,
    vehicleTypeFilter,
    vehicleCompanyFilter,
    hubFilter,
    cityFilter,
    apidata,
    searchBy,
  ]);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const removeFilters = () => {
    setStatusFilter("");
    setVehicleTypeFilter("");
    setVehicleCompanyFilter("");
    setHubFilter("");
    setCityFilter("");
    setShowFilterModal(false);
  };

  // Handle company button click to filter by the selected company
  const handleCompanyClick = (company) => {
    setVehicleCompanyFilter(company); // Set the company as the filter
    setStatusFilter("Active");
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2 d-flex align-items-center">
          <button
            className="btn btn-primary me-2"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
          <div>
            {Object.entries(vehicleCompanyCounts).map(([company, count]) => (
              <span
                key={company}
                className="btn btn-success btn-sm me-2"
                onClick={() => handleCompanyClick(company)} // Add click event to filter by company
                style={{ cursor: "pointer" }} // Add pointer cursor for better UX
              >
                {company} - {count}
              </span>
            ))}
          </div>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        vehicleTypeFilter={vehicleTypeFilter}
        setVehicleTypeFilter={setVehicleTypeFilter}
        vehicleCompanyFilter={vehicleCompanyFilter}
        setVehicleCompanyFilter={setVehicleCompanyFilter}
        cityFilter={cityFilter}
        setCityFilter={setCityFilter}
        hubFilter={hubFilter}
        setHubFilter={setHubFilter}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
      />
    </div>
  );
}
