import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    let filtered = apidata;

    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "name") {
          // Access 'name' inside performedBy
          return (
            item.performedBy &&
            item.performedBy.name &&
            item.performedBy.name
              .toLowerCase()
              .startsWith(dataFilter.toLowerCase())
          );
        }
        return false;
      });
    }

    setFilteredData(filtered);
  }, [dataFilter, apidata, searchBy]);

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
    </div>
  );
}
