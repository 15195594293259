export const extractCityFromLocation = (assignedLocation) => {
  if (!assignedLocation) return "Unknown";
  if (
    assignedLocation
      .toLowerCase()
      .includes("Mohali" || "Kharar" || "Chandigarh" || "Daria")
  ) {
    return "Mohali";
  }
  if (assignedLocation.toLowerCase().includes("New Delhi" || "Delhi")) {
    return "Delhi";
  }
  // Split the location string by commas
  const locationParts = assignedLocation.split(",");

  // Return the second-to-last element if it exists
  const lastThirdPart =
    locationParts.length >= 3
      ? locationParts[locationParts.length - 3].trim()
      : "Unknown";

  const city = lastThirdPart.split(" ");

  const cityName = city[0].trim();

  if (cityName == "Sahibzada") {
    return "Mohali";
  }

  return cityName;
};
