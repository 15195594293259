import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { extractCityFromLocation } from "../../../utils/extractCity";
export default function FilterModal({
  show,
  onHide,
  statusFilter,
  setStatusFilter,
  vehicleTypeFilter,
  setVehicleTypeFilter,
  vehicleCompanyFilter,
  setVehicleCompanyFilter,
  hubFilter,
  setHubFilter,
  cityFilter,
  setCityFilter,
  applyFilters,
  removeFilters,
  apidata,
}) {
  const cities = Array.from(
    new Set(
      apidata.map((item) => extractCityFromLocation(item.assignedlocation))
    )
  ).filter((city) => city && city !== "Unknown"); // Filter out empty and "Unknown" values
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="statusFilter">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="unassigned">Unassigned</option>
              <option value="Service_Center">Service_Center</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="vehicleTypeFilter">
            <Form.Label>Vehicle Type</Form.Label>
            <Form.Control
              as="select"
              value={vehicleTypeFilter}
              onChange={(e) => setVehicleTypeFilter(e.target.value)}
            >
              <option value="">All Vehicle Types</option>
              {Array.from(
                new Set(apidata.map((item) => item.id_type?.vehiclename))
              )
                .filter(Boolean)
                .map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="hubFilter">
            <Form.Label>Hubs</Form.Label>
            <Form.Control
              as="select"
              value={hubFilter || ""}
              onChange={(e) => setHubFilter(e.target.value)}
            >
              <option value="">All Hubs</option>
              {apidata &&
                Array.from(
                  new Set(
                    apidata.flatMap((item) => {
                      if (Array.isArray(item.id_hub)) {
                        return item.id_hub.map((hub) => hub.name);
                      }
                      return [];
                    })
                  )
                )
                  .filter(Boolean) // Ensure all names are valid
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="vehicleCompanyFilter">
            <Form.Label>Vehicle Company</Form.Label>
            <Form.Control
              as="select"
              value={vehicleCompanyFilter}
              onChange={(e) => setVehicleCompanyFilter(e.target.value)}
            >
              <option value="">All Vehicle Companies</option>
              {Array.from(new Set(apidata.map((item) => item.id_type?.company)))
                .filter(Boolean)
                .map((company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          {/* New Form Group for City Filter */}
          <Form.Group controlId="cityFilter">
            <Form.Label>City</Form.Label>
            <Form.Control
              as="select"
              value={cityFilter}
              onChange={(e) => setCityFilter(e.target.value)}
            >
              <option value="">All Cities</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
