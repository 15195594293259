import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Layouts from "../../layouts/layouts";

export default function Create_Type() {
  const title = "Add Type";
  const [formData, setFormData] = useState({
    vehiclename: "",
    company: "",
    vehicle_type: "",
    vehicleCategory: "",
  });
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: { latitude: lat, longitude: lng },
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.vehiclename) newErrors.name = "Vehicle Type is required";
    if (!formData.company) newErrors.address = "Company is required";
    if (!formData.vehicle_type)
      newErrors.vehicle_type = "Vehicle Category is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const formDataToSubmit = {
        vehiclename: formData.vehiclename,
        company: formData.company,
        vehicle_type: formData.vehicle_type,
        vehicleCategory: formData.vehicleCategory,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle-type/new`,
        formDataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/type");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="company">
                  <b> Company *</b>
                </label>
                <input
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  className="form-control"
                  onChange={onChangeHandler}
                  value={formData.company}
                />
                {errors.name && (
                  <small className="text-danger">{errors.company}</small>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="vehiclename">
                  <b>Vehicle Type *</b>
                </label>
                <input
                  type="text"
                  name="vehiclename"
                  placeholder="Vehicle Type"
                  className="form-control"
                  value={formData.vehiclename}
                  onChange={onChangeHandler}
                />
                {errors.vehiclename && (
                  <small className="text-danger">{errors.vehiclename}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="vehicle_type">
                  <b>Wheeler Type *</b>
                </label>
                <select
                  name="vehicle_type"
                  className="form-control"
                  value={formData.vehicle_type}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="2W">2W</option>
                  <option value="3W">3W</option>
                  <option value="4W">4W</option>
                </select>
                {errors.vehicle_type && (
                  <small className="text-danger">{errors.vehicle_type}</small>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="vehicleCategory">
                  <b>Category </b>
                </label>
                <select
                  name="vehicleCategory"
                  className="form-control"
                  value={formData.vehicleCategory}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Vehicle Category</option>
                  <option value="Ev">EV</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Cng">CNG</option>
                  <option value="Petrol">Petrol</option>
                </select>
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Type
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
