import "./layout.css";
import "boxicons";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Admin_icon from "../assets/photo/admin.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronDown,
  faListUl,
  faBuildingColumns,
  faIndent,
  faFileInvoice,
  faFileCode,
  faUsersGear,
  faStore,
  faGear,
  faMobileScreen,
  faGlobe,
  faWrench,
  faLifeRing,
  faGaugeHigh,
  faEye,
  faPenToSquare,
  faEnvelope,
  faBell,
  faRing,
  faKey,
  faPuzzlePiece,
  faUniversalAccess,
  faTree,
  faMessage,
  faListCheck,
  faCircleInfo,
  faPeopleGroup,
  faVideo,
  faBars,
  faX,
  faUsers,
  faTruck,
  faWarehouse,
  faUserTie,
  faSquare,
  faClipboardUser,
  faExchangeAlt,
  faCircleLeft,
  faPersonCircleCheck,
  faPeopleArrows,
  faPeopleRobbery,
  faPerson,
  faFaceDizzy,
  faFaceGrinBeamSweat,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
export default function Layouts() {
  const pathname = window.location.pathname;
  const [render, setRender] = useState(true);
  const [headerToggle, setHeaderToggle] = useState(false);
  const [sidebar, setSidebar] = useState("");
  const [headerNavManu, setheaderNavManu] = useState(true);
  const [email, setEmail] = useState(null);
  const [isReportOpen, setIsReportOpen] = useState(false); // State for reports menu
  const [isStaffOpen, setIsStaffOpen] = useState(false);
  const [isAssetsOpen, setIsAssetsOpen] = useState(false);
  const [isExpenseOpen, setIsExpenseOpen] = useState(false);
  const [isDamageOpen, setIsDamageOpen] = useState(false);
  const [isVehiclesOpen, setIsVehiclesOpen] = useState(false);

  const toggleReportMenu = () => {
    setIsReportOpen(!isReportOpen); // Toggle the submenu visibility
  };
  const toggleStaffMenu = () => {
    setIsStaffOpen(!isStaffOpen); // Toggle submenu visibility
  };
  const toggleAssetsMenu = () => {
    setIsAssetsOpen(!isAssetsOpen); // Toggle submenu visibility
  };
  const toggleExpenseMenu = () => {
    setIsExpenseOpen(!isExpenseOpen); // Toggle submenu visibility
  };
  const toggleDamageMenu = () => {
    setIsDamageOpen(!isDamageOpen); // Toggle submenu visibility for DAMAGE
  };
  const toggleVehiclesMenu = () => {
    setIsVehiclesOpen(!isVehiclesOpen); // Toggle submenu visibility for VEHICLES
  };

  useEffect(() => {
    if (
      pathname == "/login" ||
      pathname == "/forgot-password" ||
      pathname == "/register"
    ) {
      setSidebar("hide");
    } else {
      setSidebar(true);
    }
  }, [pathname]);
  const headerTogglehandle = () => {
    setHeaderToggle(!headerToggle);
    setheaderNavManu(!headerNavManu);
  };
  var body_pd = document.getElementById("body-pd");
  useEffect(() => {
    window.innerWidth >= 768 &&
      (headerToggle
        ? body_pd.classList.add("body-pd")
        : body_pd.classList.remove("body-pd"));
    if (document.getElementById("header") !== null) {
      headerToggle &&
        document.getElementById("header").classList.add("body-pd");
      headerToggle !== true &&
        document.getElementById("header").classList.remove("body-pd");
    }
  }, [headerToggle]);

  const updateActive = () => {
    setRender(!render);
  };

  const logoutHandle = () => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          Swal.fire("No token found", "", "error");
          return;
        }
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/user/logout`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              localStorage.removeItem("token"); // Remove token from local storage
              Swal.fire("Logout Success", "", "success").then(() => {
                window.location.href = "/login"; // Redirect to login page
              });
            } else {
              Swal.fire("Logout Failed", "", "error");
            }
          })
          .catch((error) => {
            Swal.fire("Logout Failed", "", "error");
            console.error("Error during logout:", error);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  useEffect(() => {
    // Fetch user's email from the backend API
    const fetchEmail = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const decodedToken = jwtDecode(token);

        const loggedInUserId = decodedToken.id;

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const users = response.data.users;

        // Find the logged-in user's information by matching the ID
        const user = users.find((user) => user._id === loggedInUserId);

        if (user) {
          setEmail(user.email);
        } else {
          console.error("Logged-in user's email not found in response data.");
        }
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchEmail();
  }, []);

  return (
    <div className={sidebar == "hide" ? "d-none" : ""}>
      <header className="header mb-4 dropdown" id="header">
        <div
          onClick={headerTogglehandle}
          className="header_toggle"
          id="header-toggle"
        >
          {headerNavManu ? (
            <FontAwesomeIcon icon={faBars} />
          ) : (
            <FontAwesomeIcon icon={faX} />
          )}{" "}
        </div>
        <div
          className="d-flex align-items-center dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <span className="header_img">
            {" "}
            <img src={Admin_icon} alt="admin icon" />{" "}
          </span>{" "}
          <span className="ms-1">{email}</span>{" "}
        </div>

        <ul
          className="dropdown-menu dropdown-menu-end"
          style={{ width: "auto", padding: "0, 2rem" }}
          aria-labelledby="dropdownMenuButton1"
        >
          <li>
            <Link className="dropdown-item" to="/profileSetting">
              Profile
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/general-setting">
              Setting
            </Link>
          </li>
          <li className="dropdown-item" onClick={logoutHandle}>
            Log Out
          </li>
        </ul>
      </header>

      <div className="manubar">
        <div className={`l-navbar ${headerToggle ? "show" : " "}`} id="nav-bar">
          <nav className="nav">
            <div>
              {" "}
              <a href="#" className="nav_logo">
                {" "}
                <i className="bx bx-layer nav_logo-icon"></i>{" "}
                <span className="nav_logo-name">Admin Panel</span>{" "}
              </a>
              <div className="nav_list">
                <Link
                  onClick={updateActive}
                  to="/"
                  className={`nav_link ${pathname == "/" ? "active" : ""}`}
                >
                  <i className="bx bx-grid-alt nav_icon"></i>
                  <span className="nav_name">DASHBOARD</span>
                </Link>

                <div className="nav_list">
                  {/* Reports Section */}
                  <div
                    className={`nav_link ${isReportOpen ? "active" : ""}`}
                    onClick={toggleReportMenu}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={isReportOpen ? faChevronUp : faChevronDown}
                    />
                    <span className="nav_name">
                      <b>REPORTS</b>
                    </span>
                  </div>
                </div>
                {/* Submenu links */}
                {isReportOpen && (
                  <div className="submenu">
                    <Link
                      to="/orderreport"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/orderreport" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faWarehouse} />
                      <span className="nav_name">Hub Reports</span>
                    </Link>

                    <Link
                      to="/ordervehiclereport"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/ordervehiclereport" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faTruck} />
                      <span className="nav_name">Vehicle Reports</span>
                    </Link>

                    <Link
                      to="/orderuserreport"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/orderuserreport" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faUsers} />
                      <span className="nav_name">User Reports</span>
                    </Link>

                    <Link
                      to="/expensereport"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/expensereport" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faUsers} />
                      <span className="nav_name">Outlay Reports</span>
                    </Link>

                    <Link
                      to="/vehicleutilization"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/vehicleutilization" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faTruck} />
                      <span className="nav_name">Vehicle Utilization R</span>
                    </Link>

                    <Link
                      to="/hubutilization"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/hubutilization" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faTruck} />
                      <span className="nav_name">Hub Utilization R</span>
                    </Link>

                    <Link
                      to="/movingnotmoving"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname == "/movingnotmoving" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faWarehouse} />
                      <span className="nav_name">Moving/Not-Moving</span>
                    </Link>
                  </div>
                )}

                <Link
                  to="/hub"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/hub" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faWarehouse} />
                  <span className="nav_name">HUBS</span>
                </Link>

                <Link
                  to="/client"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/client" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPersonCircleCheck} />
                  <span className="nav_name">CLIENTS</span>
                </Link>
                <div
                  className={`nav_link ${isStaffOpen ? "active" : ""}`}
                  onClick={toggleStaffMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={isStaffOpen ? faChevronUp : faChevronDown}
                  />
                  <span className="nav_name">
                    <b>GENERAL-STAFF</b>
                  </span>
                </div>

                {/* Submenu links */}
                {isStaffOpen && (
                  <>
                    <Link
                      to="/generalstaff"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/generalstaff" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name"> General Staff</span>
                    </Link>

                    <Link
                      to="/generalstafftype"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/generalstafftype" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">General Staff Type</span>
                    </Link>
                  </>
                )}
                <Link
                  to="/user"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/user" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="nav_name">USERS</span>
                </Link>

                <Link
                  to="/driver"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/driver" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPerson} />
                  <span className="nav_name">DRIVERS</span>
                </Link>

                <Link
                  to="/compliance"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/compliance" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPerson} />
                  <span className="nav_name">COMPLIANCE</span>
                </Link>

                <Link
                  to="/vendor"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/vendor" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUserTie} />
                  <span className="nav_name">VENDORS</span>
                </Link>

                <div
                  className={`nav_link ${isVehiclesOpen ? "active" : ""}`}
                  onClick={toggleVehiclesMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={isVehiclesOpen ? faChevronUp : faChevronDown}
                  />
                  <span className="nav_name">VEHICLES</span>
                </div>

                {/* Submenu links */}
                {isVehiclesOpen && (
                  <>
                    <Link
                      to="/vehicle"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/vehicle" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faTruck} />
                      <span className="nav_name">Vehicles</span>
                    </Link>

                    <Link
                      to="/type"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/type" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faWarehouse} />
                      <span className="nav_name">Vehicle Type</span>
                    </Link>
                  </>
                )}

                <Link
                  to="/hau"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/hau" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">HUB ADMINS</span>
                </Link>

                <Link
                  onClick={updateActive}
                  to="/activeinactive"
                  className={`nav_link ${pathname == "/" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faPeopleArrows} />
                  <span className="nav_name">ACTIVE-INACTIVE</span>
                </Link>

                <Link
                  to="/swap"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/swap" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  {/* <FontAwesomeIcon icon={faTruck} /> */}
                  <span className="nav_name">SWAP VEHICLES</span>
                </Link>

                <Link
                  to="/advance"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/advance" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  {/* <FontAwesomeIcon icon={faTruck} /> */}
                  <span className="nav_name">ADVANCE Taken</span>
                </Link>
                <Link
                  to="/backup"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/backup" ? "active" : ""
                  }`}
                >
                  {/* <FontAwesomeIcon icon={faTruck} /> */}
                  <FontAwesomeIcon icon={faCircleLeft} />
                  <span className="nav_name">BACKUPS</span>
                </Link>

                <Link
                  to="/defaulter"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/defaulter" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPeopleRobbery} />
                  <span className="nav_name">DEFAULTERS</span>
                </Link>

                <Link
                  to="/order"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/order" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">ORDERS / REVENUE</span>
                </Link>

                <Link
                  to="/payout"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/payout" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faWarehouse} />
                  <span className="nav_name">PAYOUTS</span>
                </Link>

                <div
                  className={`nav_link ${isDamageOpen ? "active" : ""}`}
                  onClick={toggleDamageMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={isDamageOpen ? faChevronUp : faChevronDown}
                  />
                  <span className="nav_name">DAMAGE</span>
                </div>

                {/* Submenu links */}
                {isDamageOpen && (
                  <>
                    <Link
                      to="/damagecategory"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/damagecategory" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">Damage Category</span>
                    </Link>

                    <Link
                      to="/damage"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/damage" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">Damages</span>
                    </Link>
                  </>
                )}

                <Link
                  to="/ticket"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/ticket" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">TICKETS</span>
                </Link>

                <div
                  className={`nav_link ${isExpenseOpen ? "active" : ""}`}
                  onClick={toggleExpenseMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={isExpenseOpen ? faChevronUp : faChevronDown}
                  />
                  <span className="nav_name">Outlay</span>
                </div>

                {/* Submenu links */}
                {isExpenseOpen && (
                  <>
                    <Link
                      to="/expensecategory"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/expensecategory" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">Expense Category</span>
                    </Link>

                    <Link
                      to="/expense"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/expense" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">Expenses</span>
                    </Link>
                  </>
                )}

                <div
                  className={`nav_link ${isAssetsOpen ? "active" : ""}`}
                  onClick={toggleAssetsMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={isAssetsOpen ? faChevronUp : faChevronDown}
                  />
                  <span className="nav_name">ASSETS</span>
                </div>

                {/* Submenu links */}
                {isAssetsOpen && (
                  <>
                    <Link
                      to="/assettype"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/assettype" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">Asset Type</span>
                    </Link>

                    <Link
                      to="/asset"
                      onClick={updateActive}
                      className={`nav_link ${
                        pathname === "/asset" ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faClipboardUser} />
                      <span className="nav_name">Assets</span>
                    </Link>
                  </>
                )}

                <Link
                  to="/salary"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/salary" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faWarehouse} />
                  <span className="nav_name">SALARY</span>
                </Link>

                <Link
                  to="/allusers"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/allusers" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="nav_name">All USERS</span>
                </Link>

                <Link
                  onClick={updateActive}
                  to="/leave"
                  className={`nav_link ${pathname == "/leave" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faFaceGrinBeamSweat} />
                  <span className="nav_name">Leaves</span>
                </Link>

                <Link
                  to="/auditlog"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/auditlog" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="nav_name">Audit Logs</span>
                </Link>

                <Link
                  to="/fakelocation"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/fakelocation" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="nav_name">Fake Location</span>
                </Link>

                <Link onClick={logoutHandle} to="#" className={`nav_link `}>
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span className="nav_name">LOGOUT</span>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
