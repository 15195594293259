import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function FilterModal({
  show,
  onHide,
  applyFilters,
  removeFilters,
  apidata,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  hubFilter,
  setHubFilter,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="fromDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              className="form-control"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="toDate">
            <Form.Label>To Date</Form.Label>
            <Form.Control
              type="date"
              className="form-control"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Form.Group>
        </Form>
        <Form.Group controlId="hubFilter">
          <Form.Label>Hubs</Form.Label>
          <Form.Control
            as="select"
            value={hubFilter || ""}
            onChange={(e) => setHubFilter(e.target.value)}
          >
            <option value="">All Hubs</option>
            {apidata &&
              Array.from(
                new Set(
                  apidata.flatMap((item) => {
                    if (
                      item.tripSummary &&
                      typeof item.tripSummary === "object"
                    ) {
                      return Object.values(item.tripSummary).map(
                        (summary) => summary.hub
                      );
                    }
                    return [];
                  })
                )
              )
                .filter(Boolean) // Ensure all names are valid
                .map((hub, index) => (
                  <option key={index} value={hub}>
                    {hub}
                  </option>
                ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
