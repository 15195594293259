import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/hubs/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/hubs/update_data_modal";
import HubDetailModal from "../../components/hubs/Hub Details";

export default function Hub() {
  const navigate = useNavigate();
  const title = "Hub Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [hubCount, setHubCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHubDetailModal, setShowHubDetailModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Name</b>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <b>Address</b>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <b>City</b>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <b>State</b>,
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: <b>Vehicle Count</b>,
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: <b>Created At</b>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: <b>Modified At</b>,
      selector: (row) =>
        row.updatedAt && new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },

    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => viewHub(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => handleHubHistoryClick(row)}
            className="btn btn-warning btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faHistory} />
          </button>
        </>
      ),
    },
  ];
  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };
  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.hubs)) {
        setApiState(response.data.hubs);
        setHubCount(response.data.hubCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const viewHub = (hub) => {
    setSelectedHub(hub);
    setShowHubDetailModal(true);
  };
  const updateHub = (updatedHub) => {
    setApiState((prev) =>
      prev.map((hub) => (hub._id === updatedHub._id ? updatedHub : hub))
    );
  };

  const handleHubHistoryClick = (row) => {
    navigate(`/hubhistory/${row._id}`);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateHub={updateHub}
      />
      <HubDetailModal
        show={showHubDetailModal}
        onHide={() => setShowHubDetailModal(false)}
        hub={selectedHub}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create-hub">
                  <div className="btn btn-info btn-sm text-white">Add Hub</div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
