import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import { Country, State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

export default function Create_payout() {
  const title = "Add Payout";
  const [formData, setFormData] = useState({
    hubId: "",
    clientId: "",
    amount: {
      orderPicked: "",
      orderDeliver: "",
    },
    vehicleType: "",
    threshold: "",
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const [hubs, setHubs] = useState([]);
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];
  const [users, setUsers] = useState([]);

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    if (name === "orderPicked" || name === "orderDeliver") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        amount: {
          ...prevFormData.amount,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.clientId) newErrors.clientId = "Client is required.";
    if (!formData.vehicleType)
      newErrors.vehicleType = "Vehicle type is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("hubId", formData.hubId);
    data.append("clientId", formData.clientId);
    data.append("amount.orderPicked", formData.amount?.orderPicked || 0);
    data.append("amount.orderDeliver", formData.amount?.orderDeliver || 0);
    data.append("vehicleType", formData.vehicleType);
    data.append("threshold", formData.threshold);

    try {
      console.log("I am in Try Block");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/payout/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/payout");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error);
    }
  };
  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHubs(response.data.hubs);
      } catch (error) {
        console.error("Error fetching hub:", error);
        setHubs([]);
      }
    };
    fetchHubs();
  }, []);

  const hubOptions = hubs.map((hub) => ({
    value: hub._id,
    label: hub.name,
  }));

  const handleHubChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hubId: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setClients(response.data.clients);
      } catch (error) {
        console.error("Error fetching client:", error);
        setClients([]);
      }
    };
    fetchClients();
  }, []);

  const clientOptions = clients.map((client) => ({
    value: client._id,
    label: client.name,
  }));

  const handleClientChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      clientId: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="hub">
                  <b>Hub </b>
                </label>
                <Select
                  name="hubId"
                  options={hubOptions}
                  onChange={handleHubChange}
                  value={hubOptions.find(
                    (option) => option.value === formData.hubId
                  )}
                  isClearable
                  placeholder="Select Hub"
                  className="basic-single"
                  classNamePrefix="select"
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="client">
                  <b>Clients *</b>
                </label>
                <Select
                  name="clientId"
                  options={clientOptions}
                  onChange={handleClientChange}
                  value={clientOptions.find(
                    (option) => option.value === formData.clientId
                  )}
                  isClearable
                  placeholder="Select Client"
                  className="basic-single"
                  classNamePrefix="select"
                />
                {errors.clientId && (
                  <p className="text-danger">{errors.clientId}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="vehicleType">
                  <b>Vehicle Type *</b>
                </label>
                <select
                  name="vehicleType"
                  className="form-control"
                  value={formData.vehicleType || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Type</option>
                  <option value="2W">2-Wheeler</option>
                  <option value="3W">3-Wheeler</option>
                  <option value="4W">4-Wheeler</option>
                </select>
                {errors.vehicleType && (
                  <p className="text-danger">{errors.vehicleType}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="orderPicked">
                  <b>Amount Order Picked *</b>
                </label>
                <input
                  type="number"
                  placeholder="Order Picked"
                  className="form-control"
                  name="orderPicked"
                  onChange={onChangeHandler}
                  value={formData.amount?.orderPicked || ""}
                />
                {errors?.orderPicked && (
                  <p className="text-danger">{errors.orderPicked}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="orderDeliver">
                  <b>Amount Order Deliver *</b>
                </label>
                <input
                  type="number"
                  placeholder="Order Deliver"
                  className="form-control"
                  name="orderDeliver"
                  onChange={onChangeHandler}
                  value={formData.amount?.orderDeliver || ""}
                />
                {errors?.orderDeliver && (
                  <p className="text-danger">{errors.orderDeliver}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="threshold">
                  <b>Threshold *</b>
                </label>
                <input
                  type="number"
                  placeholder="Threshold"
                  className="form-control"
                  name="threshold"
                  onChange={onChangeHandler}
                  value={formData.threshold || ""}
                />
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Payout
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
