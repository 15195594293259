import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateCompliance,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);

  const [errors, setErrors] = useState({}); // State to hold validation errors
  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      const selectedClient = select_data.id_client.map((item) => ({
        value: item._id,
        label: clients.find((client) => client._id === item._id).name || "",
      }));
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: select_data.id_user._id,
        // id_user: select_data.id_client._id,

        // id_user: select_data.id_user ? select_data.id_user._id : null,
        id_client: [...selectedClient],
      }));
    }
  }, [select_data, users, clients]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.clients);
        setClients(response.data.clients);
        console.log("Clients fetched:", response.data.clients);
      } catch (error) {
        console.error("Error fetching client:", error);
        setClients([]);
      }
    };
    fetchClients();
  }, []);
  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "id_user") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: { ...prevData.id_user, [name]: value },
      }));
    } else if (name === "client_id_verification") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        client_id_verification: {
          ...prevData.client_id_verification,
          isDone: value === "true" ? true : false,
        },
      }));
    } else if (name === "license_id") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "aadhar_id") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "police_verification") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "consent_document") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "id_client") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_client: { ...prevData.id_client, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    // Validate required fields
    if (!update_modal_data.id_user) {
      errors.id_user = "User is required";
    }
    if (!update_modal_data.aadhar_number) {
      errors.aadhar_number = "Adhar Number is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if errors object is empty
  };

  const handleUpdate = async () => {
    console.log("Update button clicked");

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }

    try {
      console.log("Entered in try block");
      const { _id } = update_modal_data;
      if (!_id) {
        throw new Error("ID is not defined");
      }

      const formData = new FormData();
      if (Array.isArray(update_modal_data.id_client)) {
        update_modal_data.id_client.forEach((client, index) => {
          formData.append(`id_client[${index}]`, client.value || client);
        });
      }
      formData.append("license_number", update_modal_data.license_number);
      formData.append("aadhar_number", update_modal_data.aadhar_number);
      formData.append("induction", update_modal_data.induction);
      formData.append("isConsent", update_modal_data.isConsent);
      formData.append(
        "client_id_verification.isDone",
        update_modal_data.client_id_verification?.isDone
      );

      formData.append("offer_letter", update_modal_data.offer_letter);

      formData.append("permanentaddress", update_modal_data.permanentaddress);
      formData.append("id_user", update_modal_data.id_user);
      formData.append("status", update_modal_data.status);

      if (update_modal_data.license_id instanceof FileList) {
        formData.append("license_id", update_modal_data.license_id[0]);
      }
      if (update_modal_data.aadhar_id instanceof FileList) {
        formData.append("aadhar_id", update_modal_data.aadhar_id[0]);
      }
      if (update_modal_data.consent_document instanceof FileList) {
        formData.append(
          "consent_document",
          update_modal_data.consent_document[0]
        );
      }
      if (update_modal_data.police_verification instanceof FileList) {
        formData.append(
          "police_verification",
          update_modal_data.police_verification[0]
        );
      }
      console.log("FORMDATA", formData);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v3/compliance/${_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API HITED");

      if (response.data.success) {
        Swal.fire("Update success", "", "success");
        onUpdateCompliance(update_modal_data); // Update locally
        setRen(!ren); // Trigger rerender if necessary
      } else {
        Swal.fire("Update failed", "", "error");
      }

      set_update_data_modal_Show(false); // Close modal on success or failure
    } catch (error) {
      Swal.fire("Update failed", "", "error");
      console.error("Error updating compliance:", error);
    }
  };
  const handleClientChange = (selectedOptions) => {
    const clientIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_client: clientIds,
    }));
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Compliance Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Client *</Form.Label>
              <Select
                name="id_client"
                defaultValue={update_modal_data.id_client || ""}
                options={clients.map((client) => ({
                  value: client._id,
                  label: client.name,
                }))}
                isMulti
                onChange={handleClientChange}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="isDone">
              <Form.Label>Client ID Verification *</Form.Label>
              <select
                name="isDone"
                className="form-control"
                value={
                  update_modal_data.client_id_verification?.isDone
                    ? "true"
                    : "false"
                }
                onChange={(e) =>
                  setUpdate_modal_data((prevData) => ({
                    ...prevData,
                    client_id_verification: {
                      ...prevData.client_id_verification,
                      isDone: e.target.value === "true",
                    },
                  }))
                }
              >
                <option value="true">Done</option>
                <option value="false">Pending</option>
              </select>
              {errors.client_id_verification?.isDone && (
                <Form.Control.Feedback type="invalid">
                  {errors.client_id_verification?.isDone}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="license_number">
              <Form.Label>License Number *</Form.Label>
              <Form.Control
                type="number"
                name="license_number"
                value={update_modal_data.license_number || ""}
                onChange={handleChange}
                isInvalid={!!errors.license_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.license_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="aadhar_number">
              <Form.Label>Adhar Card Number *</Form.Label>
              <Form.Control
                type="number"
                name="aadhar_number"
                value={update_modal_data.aadhar_number || ""}
                onChange={handleChange}
                isInvalid={!!errors.aadhar_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.aadhar_number}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="induction">
              <Form.Label>Induction</Form.Label>
              <select
                name="induction"
                className="form-control"
                value={update_modal_data.induction ? "true" : "false"} // Convert boolean to string for select value
                onChange={(e) =>
                  setUpdate_modal_data((prevData) => ({
                    ...prevData,
                    induction: e.target.value === "true", // Convert string to boolean
                  }))
                }
              >
                <option value="true">Done</option>
                <option value="false">Pending</option>
              </select>
              {errors.induction && (
                <Form.Control.Feedback type="invalid">
                  {errors.induction}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="isConsent">
              <Form.Label>Is Consent</Form.Label>
              <select
                name="isConsent"
                className="form-control"
                value={update_modal_data.isConsent ? "true" : "false"} // Convert boolean to string for select value
                onChange={(e) =>
                  setUpdate_modal_data((prevData) => ({
                    ...prevData,
                    isConsent: e.target.value === "true", // Convert string to boolean
                  }))
                }
              >
                <option value="true">Done</option>
                <option value="false">Pending</option>
              </select>
              {errors.isConsent && (
                <Form.Control.Feedback type="invalid">
                  {errors.isConsent}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="offer_letter">
              <Form.Label>Offer Letter</Form.Label>
              <select
                name="offer_letter"
                className="form-control"
                value={update_modal_data.offer_letter ? "true" : "false"} // Convert boolean to string for select value
                onChange={(e) =>
                  setUpdate_modal_data((prevData) => ({
                    ...prevData,
                    offer_letter: e.target.value === "true", // Convert string to boolean
                  }))
                }
              >
                <option value="true">Done</option>
                <option value="false">Pending</option>
              </select>
              {errors.offer_letter && (
                <Form.Control.Feedback type="invalid">
                  {errors.offer_letter}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_user">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.email}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status *</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Driver License *</Form.Label>
              <Form.Control
                type="file"
                name="license_id"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Group>
            {(update_modal_data.license_id &&
              update_modal_data.license_id[0]) ||
            select_data.license_id?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Identification Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.license_id &&
                      update_modal_data.license_id[0]
                        ? URL.createObjectURL(update_modal_data.license_id[0])
                        : select_data.license_id?.url
                    }
                    alt="Identification Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}

            <Form.Group className="mb-3">
              <Form.Label>Govt. Identification *</Form.Label>
              <Form.Control
                type="file"
                name="aadhar_id"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Group>
            {(update_modal_data.aadhar_id && update_modal_data.aadhar_id[0]) ||
            select_data.aadhar_id?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Identification Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.aadhar_id &&
                      update_modal_data.aadhar_id[0]
                        ? URL.createObjectURL(update_modal_data.aadhar_id[0])
                        : select_data.aadhar_id?.url
                    }
                    alt="Identification Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}

            <Form.Group className="mb-3">
              <Form.Label>Police verification *</Form.Label>
              <Form.Control
                type="file"
                name="police_verification"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Group>
            {(update_modal_data.police_verification &&
              update_modal_data.police_verification[0]) ||
            select_data.police_verification?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Identification Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.police_verification &&
                      update_modal_data.police_verification[0]
                        ? URL.createObjectURL(
                            update_modal_data.police_verification[0]
                          )
                        : select_data.police_verification?.url
                    }
                    alt="Identification Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}

            <Form.Group className="mb-3">
              <Form.Label>Consent Document *</Form.Label>
              <Form.Control
                type="file"
                name="consent_document"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Group>
            {(update_modal_data.consent_document &&
              update_modal_data.consent_document[0]) ||
            select_data.consent_document?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Identification Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.consent_document &&
                      update_modal_data.consent_document[0]
                        ? URL.createObjectURL(
                            update_modal_data.consent_document[0]
                          )
                        : select_data.consent_document?.url
                    }
                    alt="Identification Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Compliance
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
