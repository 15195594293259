import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdatePayout,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const [hubs, setHubs] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        hubId: select_data.hubId?._id,
        clientId: select_data.clientId?._id,
      }));
    }
  }, [select_data, hubs, clients]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "orderPicked" || name === "orderDeliver") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        amount: {
          ...prevData.amount,
          [name]: value,
        },
      }));
    } else {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!update_modal_data.clientId)
      newErrors.clientId = "Client assignment is required";
    if (!update_modal_data.vehicleType)
      newErrors.vehicleType = "Vehicle Type is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }

    try {
      const { _id } = update_modal_data;
      if (!_id) {
        throw new Error("ID is not defined");
      }

      const formData = new FormData();
      formData.append("hubId", update_modal_data.hubId);
      formData.append("clientId", update_modal_data.clientId);
      formData.append("threshold", update_modal_data.threshold || "");
      formData.append(
        "amount.orderPicked",
        update_modal_data.amount?.orderPicked || 0
      );
      formData.append(
        "amount.orderDeliver",
        update_modal_data.amount?.orderDeliver || 0
      );

      formData.append("vehicleType", update_modal_data.vehicleType);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v2/payout/${_id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire("Update success", "", "success");
        onUpdatePayout(update_modal_data); // Update locally
        setRen(!ren); // Trigger rerender if necessary
      } else {
        Swal.fire("Update failed", "", "error");
      }

      set_update_data_modal_Show(false); // Close modal on success or failure
    } catch (error) {
      Swal.fire("Update failed", "", "error");
      console.error("Error updating salary:", error);
    }
  };
  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHubs(response.data.hubs);
      } catch (error) {
        console.error("Error fetching hub:", error);
        setHubs([]);
      }
    };
    fetchHubs(currentPage);
  }, [currentPage]);

  const handleHubChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      hubId: value,
    }));
  };
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setClients(response.data.clients);
      } catch (error) {
        console.error("Error fetching client:", error);
        setClients([]);
      }
    };
    fetchClients(currentPage);
  }, [currentPage]);

  const handleClientChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      clientId: value,
    }));
  };
  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Payout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Assign Hub </Form.Label>
              <Form.Control
                as="select"
                name="hubId"
                value={update_modal_data.hubId || ""}
                onChange={handleHubChange}
                isInvalid={!!errors.hubId}
              >
                <option value="">Select Hub</option>
                {hubs.map((hub) => (
                  <option key={hub._id} value={hub._id}>
                    {hub.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Assign Client *</Form.Label>
              <Form.Control
                as="select"
                name="clientId"
                value={update_modal_data.clientId || ""}
                onChange={handleClientChange}
                isInvalid={!!errors.clientId}
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client._id} value={client._id}>
                    {client.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.clientId}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Vehicle Type *</Form.Label>
              <Form.Control
                as="select"
                name="vehicleType"
                value={update_modal_data.vehicleType || ""}
                onChange={handleChange}
              >
                <option value="">Select Type</option>
                <option value="2W">2-Wheeler</option>
                <option value="3W">3-Wheeler</option>
                <option value="4W">4-Wheeler</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.vehicleType}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount Order Picked * </Form.Label>
              <Form.Control
                type="text"
                name="orderPicked"
                value={update_modal_data.amount?.orderPicked || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount Order Delivered *</Form.Label>
              <Form.Control
                type="text"
                name="orderDeliver"
                value={update_modal_data.amount?.orderDeliver || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Threshold * </Form.Label>
              <Form.Control
                type="number"
                name="threshold"
                value={update_modal_data.threshold || ""}
                onChange={handleChange}
                isInvalid={!!errors.threshold}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Payout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
