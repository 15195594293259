import React from "react";
import { Modal, Button } from "react-bootstrap";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const VehicleDetailModal = ({ show, onHide, hubs }) => {
  if (!hubs) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Hub Name:</strong> {hubs.hubName}
        </p>
        <p>
          <strong>Total Count:</strong> {hubs.vehicleCount}
        </p>
        <p>
          <strong>Moving Count:</strong> {hubs.movingVehicleCount}
        </p>
        <p>
          <strong>Not-Moving Count:</strong> {hubs.nonMovingVehicleCount}
        </p>
        <p>
          <strong>Moving Vehicles:</strong>{" "}
          {hubs.vehicleSummaries && hubs.vehicleSummaries.length > 0
            ? hubs.vehicleSummaries
                .filter((vehicle) => vehicle.moving === true)
                .map((vehicle) => vehicle.numberplate)
                .join(", ") || "N/A"
            : "N/A"}
        </p>

        <p>
          <strong>Not-Moving Vehicles:</strong>{" "}
          {hubs.vehicleSummaries && hubs.vehicleSummaries.length > 0
            ? hubs.vehicleSummaries
                .filter((vehicle) => vehicle.moving === false)
                .map((vehicle) => vehicle.numberplate)
                .join(", ") || "N/A"
            : "N/A"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VehicleDetailModal;
