import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/compliance/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Add_data_modal from "../../components/compliance/update_data_modal";
import ComplianceDetailModal from "../../components/compliance/Compliance Details";
import Layouts from "../../layouts/layouts";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";

export default function Compliance() {
  const title = "Compliance Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showComplianceDetailModal, setShowComplianceDetailModal] =
    useState(false);
  const [selectedCompliance, setSelectedCompliance] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Clients</b>,
      selector: (row) => {
        if (Array.isArray(row.id_client)) {
          return row.id_client.map((client) => client.name).join(", ");
        } else {
          return "N/A";
        }
      },
      sortable: true,
      style: {
        maxWidth: "400px",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      width: "150px",
    },

    {
      name: <b>Client Verification</b>,
      selector: (row) => {
        const isDone = row.client_id_verification?.isDone ?? false; // Safely access `isDone`
        return isDone ? (
          <span style={{ color: "green" }}>✔</span> // Green tick
        ) : (
          <span style={{ color: "red" }}>✖</span> // Red cross
        );
      },
      sortable: true,
    },

    {
      name: <b>Offer Letter</b>,
      selector: (row) => {
        return row.offer_letter ? (
          <span style={{ color: "green" }}>✔</span> // Green tick
        ) : (
          <span style={{ color: "red" }}>✖</span> // Red cross
        );
      },
      sortable: true,
    },
    {
      name: <b>Consent Form</b>,
      selector: (row) => {
        return row.isConsent ? (
          <span style={{ color: "green" }}>✔</span> // Green tick
        ) : (
          <span style={{ color: "red" }}>✖</span> // Red cross
        );
      },
      sortable: true,
    },
    {
      name: <b>Induction</b>,
      selector: (row) => {
        return row.induction ? (
          <span style={{ color: "green" }}>✔</span> // Green tick
        ) : (
          <span style={{ color: "red" }}>✖</span> // Red cross
        );
      },
      sortable: true,
    },

    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => viewCompliance(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchCompliance = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v3/compliances`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the entire response data to inspect its structure
      console.log("API Response:", response.data);

      // Ensure the correct data structure is accessed
      if (Array.isArray(response.data.compliances)) {
        setApiState(response.data.compliances);
      } else {
        console.error("Unexpected data format from API", response.data);
      }

      setApiCol(columns);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCompliance(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v3/compliance/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Compliance has been deleted.", "success");
            fetchCompliance(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Compliance could not be deleted.", "error");
            console.error("Error deleting Compliance:", error);
          });
      }
    });
  };

  const updateCompliance = (updatedCompliance) => {
    setApiState((prev) =>
      prev.map((compliance) =>
        compliance._id === updatedCompliance._id
          ? updatedCompliance
          : compliance
      )
    );
  };

  const viewCompliance = (compliance) => {
    setSelectedCompliance(compliance);
    setShowComplianceDetailModal(true);
  };

  const exportToExcel = () => {
    // Assuming `compliance` is an array of compliance data
    const transformedData = apistate.map((compliance) => ({
      "User Name": compliance.id_user ? compliance.id_user.name : "N/A",
      "License Number": compliance.license_number || "N/A",
      "Aadhar Card Number": compliance.aadhar_number || "N/A",
      "Client Name":
        compliance.client_id_verification?.id_client?.name || "N/A",
      "Verification Done":
        compliance.client_id_verification?.isDone !== undefined
          ? compliance.client_id_verification.isDone
            ? "Yes"
            : "No"
          : "N/A",
      Status: compliance.status || "N/A",
      "Offer Letter": compliance.offer_letter ? "Yes" : "No",
      Induction: compliance.induction ? "Yes" : "No",
      "Is Consent": compliance.isConsent ? "Yes" : "No",
      "Created By": compliance.id_user ? compliance.id_user.name : "N/A",
      "Updated At": formatDate(compliance.updatedAt || "N/A"),
    }));

    // Create Excel worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Compliance Details");

    // Trigger download of the Excel file
    XLSX.writeFile(workbook, "ComplianceDetails.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateCompliance={updateCompliance}
      />
      <ComplianceDetailModal
        show={showComplianceDetailModal}
        onHide={() => setShowComplianceDetailModal(false)}
        compliance={selectedCompliance}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-compliance">
                    <div className="btn btn-info btn-sm text-white">
                      Add Compliance
                    </div>
                  </Link>
                  <button
                    className="btn btn-success btn-sm ms-2"
                    onClick={exportToExcel}
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    apidata={apistate}
                    // columns={apicol}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
