import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";
import moment from "moment";
import axios from "axios";

export default function DataTable_Component({
  apidata,
  columns,
  searchBy,
  onFilterApply,
}) {
  const [dataFilter, setDataFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [hubFilter, setHubFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const isValidDate = (date) => {
    return moment(date, "YYYY-MM-DD", true).isValid();
  };
  const debounceFetchData = useCallback(
    debounce(() => {
      fetchExpenses();
    }, 500), // 500ms debounce delay
    [startDate, endDate]
  );

  useEffect(() => {
    if (isValidDate(startDate) && isValidDate(endDate)) {
      debounceFetchData();
    }
    return debounceFetchData.cancel; // Cleanup debounce on unmount
  }, [startDate, endDate, debounceFetchData]);

  const fetchExpenses = async () => {
    // if (!isValidDate(startDate) || !isValidDate(endDate)) {
    //   console.log("Invalid dates provided.");
    //   return;
    // }
    if (
      !startDate ||
      !endDate ||
      !isValidDate(startDate) ||
      !isValidDate(endDate)
    ) {
      console.error("Invalid or missing dates");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      // Format the dates properly before sending them to the API
      const formattedFromDate = moment(startDate).format("YYYY-MM-DD");
      const formattedToDate = moment(endDate).format("YYYY-MM-DD");

      console.log("Fetching data with dates:");
      console.log("FROM DATE", formattedFromDate);
      console.log("TO DATE", formattedToDate);

      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/expenses/date/${startDate}/${endDate} `;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data.expenses)) {
        setFilteredData(response.data.expenses);
        console.log("Data =", response.data.expenses);
      } else {
        console.error(
          "Unexpected data format from API",
          response.data.expenses
        );
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    if (!Array.isArray(apidata)) {
      console.error("Invalid apidata");
      setFilteredData([]);
      return;
    }

    let filtered = apidata;

    // Filter by hub
    if (hubFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_hub &&
          item.id_hub.name.toLowerCase().includes(hubFilter.toLowerCase())
      );
    }

    // Filter by category
    if (categoryFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_expenseCategory &&
          item.id_expenseCategory.name
            .toLowerCase()
            .includes(categoryFilter.toLowerCase())
      );
    }

    // Filter by status
    if (statusFilter) {
      filtered = filtered.filter(
        (item) =>
          item.status &&
          item.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    // Filter by date range
    if (isValidDate(startDate) && isValidDate(endDate)) {
      const start = moment(startDate, "YYYY-MM-DD");
      const end = moment(endDate, "YYYY-MM-DD");

      filtered = filtered.filter((item) => {
        const itemDate = moment(item.date, "YYYY-MM-DD"); // Adjust the field name 'date' to match your API's date field
        return itemDate.isBetween(start, end, undefined, "[]"); // Inclusive range
      });
    }

    // Filter by search term
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        // Flexible search logic for different fields
        if (searchBy === "name") {
          return (
            (item.name &&
              item.name.toLowerCase().startsWith(dataFilter.toLowerCase())) ||
            (item.id_user &&
              item.id_user.name
                .toLowerCase()
                .startsWith(dataFilter.toLowerCase())) ||
            (item.id_hub &&
              item.id_hub.name
                .toLowerCase()
                .startsWith(dataFilter.toLowerCase()))
          );
        }
        // Add more conditions as needed for other fields
        return false;
      });
    }

    setFilteredData(filtered);
  }, [
    dataFilter,
    statusFilter,
    apidata,
    searchBy,
    categoryFilter,
    hubFilter,
    startDate,
    endDate,
  ]);

  const applyFilters = () => {
    if (isValidDate(startDate) && isValidDate(endDate)) {
      setStartDate(startDate); // Triggers data fetch
      setEndDate(endDate); // Triggers data fetch
      setShowFilterModal(false);
    } else {
      // console.error("Invalid date range");
      setShowFilterModal(false);
    }
  };

  const removeFilters = () => {
    setStartDate(""); // Clear from date
    setEndDate(""); // Clear to date
    setStatusFilter("");
    setCategoryFilter("");
    setHubFilter("");
    setStartDate("");
    setEndDate("");
    setShowFilterModal(false);
    onFilterApply("", "");
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
        hubFilter={hubFilter}
        setHubFilter={setHubFilter}
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>
  );
}

function debounce(func, delay) {
  let timeout;
  const debouncedFunc = (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
  debouncedFunc.cancel = () => clearTimeout(timeout);
  return debouncedFunc;
}
