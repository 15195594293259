import { useState, useEffect } from "react";
import axios from "axios";
import DataTable_Component from "../../components/audits/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import AuditDetailModal from "../../components/audits/Audit Details";

export default function AuditLog() {
  const title = "Audit Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [auditCount, setAuditCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showAuditDetailModal, setShowAuditDetailModal] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Action</b>,
      selector: (row) => row.action,
      sortable: true,
    },
    {
      name: <b>Model</b>,
      selector: (row) => row.model,
      sortable: true,
    },
    {
      name: <b>Performed By</b>,
      selector: (row) => (row.performedBy ? row.performedBy.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Updated At</b>,
      selector: (row) =>
        row.timestamp && new Date(row.timestamp).toLocaleDateString(),
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => viewAudit(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/audit-logs`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.logs)) {
        setApiState(response.data.logs);
        setAuditCount(response.data.logCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const viewAudit = (audit) => {
    setSelectedAudit(audit);
    setShowAuditDetailModal(true);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />

      <AuditDetailModal
        show={showAuditDetailModal}
        onHide={() => setShowAuditDetailModal(false)}
        audit={selectedAudit}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
