import React from "react";
import { Modal, Button } from "react-bootstrap";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const AuditDetailModal = ({ show, onHide, audit }) => {
  if (!audit) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Audit Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Action:</strong> {audit.action}
        </p>
        <p>
          <strong>Model:</strong> {audit.model}
        </p>

        <p>
          <strong>Performed By:</strong>{" "}
          {audit.performedBy ? audit.performedBy.name : "N/A"}
        </p>
        <p>
          <strong>Updated At :</strong> {formatDate(audit.timestamp)}
        </p>
        <p>
          <strong>Action:</strong> {audit.action}
        </p>
        {audit.changes && Object.keys(audit.changes).length > 0 ? (
          <div>
            <strong>Changes:</strong>
            <ul>
              {Object.entries(audit.changes).map(([key, value]) => (
                <li key={key}>
                  <strong>{key}:</strong> {String(value)}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>
            <strong>Changes:</strong> No changes
          </p>
        )}

        {/* <p>
          <strong>Created By:</strong>{" "}
          {hub.createdBy && (
            <>
              <span>
                <strong>Name:</strong> {hub.createdBy.user_email}
              </span>
              <span>, </span>
              <span>
                <strong>Role:</strong> {hub.createdBy.user_role}
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Created Date:</strong>{" "}
          {hub.createdAt && <>{new Date(hub.createdAt).toLocaleDateString()}</>}
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AuditDetailModal;
