import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_compliance() {
  const title = "Add Compliance";
  const navigate = useNavigate();
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [clients, setClients] = useState([]);

  const [formData, setFormData] = useState({
    id_user: "",
    license_id: null,
    license_number: "",
    aadhar_id: null,
    aadhar_number: "",
    police_verification: null,
    consent_document: null,
    status: "Active",
    id_client: [],
    client_id_verification: {
      isDone: false,
    },
    induction: false,
    offer_letter: false,
    isConsent: false,
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const onChangeHandler = (event) => {
    const { name, value, files } = event.target;

    if (files) {
      console.log(`File selected for ${name}:`, files[0]); // Debug the selected file

      if (files[0].size > 1024 * 1024) {
        // Check if the file exceeds the size limit
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size exceeds 1MB limit",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: files[0], // Ensure the file is added to formData state
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.id_user) newErrors.id_user = "User is required";
    if (!formData.license_id) newErrors.license_id = "License ID is required";
    if (!formData.aadhar_id) newErrors.aadhar_id = "Aadhar Card is required";
    if (!formData.police_verification)
      newErrors.police_verification = "Police verification is required";
    if (!formData.consent_document)
      newErrors.consent_document = "Consent document is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const formDataToSend = new FormData();

    // Append form data (excluding the id_client field if there are multiple clients)
    for (const key in formData) {
      if (key === "client_id_verification") {
        formDataToSend.append(
          "client_id_verification.isDone",
          formData.client_id_verification.isDone.toString()
        );
      } else if (key === "id_client") {
        // If multiple clients, append each id_client as id_client[]
        if (
          Array.isArray(formData.id_client) &&
          formData.id_client.length > 0
        ) {
          formData.id_client.forEach((clientId) => {
            formDataToSend.append("id_client[]", clientId);
          });
        } else {
          formDataToSend.append("id_client", formData.id_client);
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    // Debugging FormData
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v3/compliance/new`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/compliance");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDateChange = (date, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: date,
    }));
  };

  const handleClientChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_client: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const handleIsDoneChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      client_id_verification: {
        ...prevFormData.client_id_verification,
        isDone: selectedOption.value,
      },
    }));
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.clients);
        setClients(response.data.clients);
        console.log("Clients fetched:", response.data.clients);
      } catch (error) {
        console.error("Error fetching client:", error);
        setClients([]);
      }
    };
    fetchClients();
  }, []);

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User </b>
                </label>
                <select
                  name="id_user"
                  className="form-control"
                  value={formData.id_user}
                  onChange={onChangeHandler}
                >
                  <option value="">Select User</option>
                  {Array.isArray(users) &&
                    users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.email}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_client">
                  <b>Client </b>
                </label>
                <Select
                  name="id_client"
                  options={clients.map((client) => ({
                    value: client._id,
                    label: `${client.name}`,
                  }))}
                  isMulti
                  onChange={handleClientChange}
                  className={`basic-multi-select ${
                    errors.id_client ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="license_id">
                  <b>Driver License *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="license_id"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="license_number">
                  <b>License Number*</b>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="license_number"
                  onChange={onChangeHandler}
                  value={formData.license_number}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="aadhar_id">
                  <b>Adhar Card *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="aadhar_id"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="aadhar_number">
                  <b>Adhar Card Number*</b>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="aadhar_number"
                  onChange={onChangeHandler}
                  value={formData.aadhar_number}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="police_verification">
                  <b>Police Verification *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="police_verification"
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="consent_document">
                  <b>Consent Document *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="consent_document"
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="isDone">
                  <b>Client ID Verification</b>
                </label>
                <Select
                  name="isDone"
                  options={[
                    { value: true, label: "Done" },
                    { value: false, label: "Pending" },
                  ]}
                  onChange={handleIsDoneChange}
                  className={`basic-select ${
                    errors.client_id_verification?.isDone ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="induction">
                  <b>Induction</b>
                </label>
                <select
                  name="induction"
                  className="form-control"
                  value={formData.induction}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      induction: e.target.value === "true", // Convert string to boolean
                    }))
                  }
                >
                  <option value="true">Done</option>
                  <option value="false">Pending</option>
                </select>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="offer_letter">
                  <b>Offer Letter</b>
                </label>
                <select
                  name="offer_letter"
                  className="form-control"
                  value={formData.offer_letter}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      offer_letter: e.target.value === "true", // Convert string to boolean
                    }))
                  }
                >
                  <option value="true">Done</option>
                  <option value="false">Pending</option>
                </select>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="isConsent">
                  <b>Consent Form</b>
                </label>
                <select
                  name="isConsent"
                  className="form-control"
                  value={formData.isConsent}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      isConsent: e.target.value === "true", // Convert string to boolean
                    }))
                  }
                >
                  <option value="true">Done</option>
                  <option value="false">Pending</option>
                </select>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b> Status</b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status || "Active"}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Compliance
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={isMapOpen}
        onRequestClose={() => setIsMapOpen(false)}
        contentLabel="Map Modal"
      ></Modal>
    </>
  );
}
