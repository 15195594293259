import React from "react";
import { Modal, Button } from "react-bootstrap";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const FakeDetailModal = ({ show, onHide, fake }) => {
  if (!fake) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Fake Location Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>User Name:</strong> {fake.id_user ? fake.id_user.name : "N/A"}
        </p>
        <p>
          <strong>User Email:</strong>{" "}
          {fake.id_user ? fake.id_user.email : "N/A"}
        </p>
        <p>
          <strong>Created At :</strong> {formatDate(fake.createdAt)}
        </p>
        {/* <p>
          <strong>Created By:</strong>{" "}
          {hub.createdBy && (
            <>
              <span>
                <strong>Name:</strong> {hub.createdBy.user_email}
              </span>
              <span>, </span>
              <span>
                <strong>Role:</strong> {hub.createdBy.user_role}
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Created Date:</strong>{" "}
          {hub.createdAt && <>{new Date(hub.createdAt).toLocaleDateString()}</>}
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FakeDetailModal;
